import React, { useState, useEffect } from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import { toast } from "react-toastify";
import {
  useGetLiveTokenDateQuery,
  useSetLiveTokenDateMutation,
  useUpdateLiveTokenDateStatusMutation,
} from "../../../redux/liveTokenDateApi";

function LiveTokenDate() {
  const { data: record } = useGetLiveTokenDateQuery();
  const [setLiveTokenDate] = useSetLiveTokenDateMutation();
  const [updateLiveTokenDateStatus]=useUpdateLiveTokenDateStatusMutation()
  const [date, setDate] = useState("");
  const [dateErr, setDateErr] = useState("");
  const [id, setId] = useState("");
  const [minDateTime, setMinDateTime] = useState("");
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (record && record?.length > 0) {
      setDate(record[0]?.live_date);
      setId(record[0]?.id);
      setStatus(record[0].status == "1" ? true : false);
    }
  }, [record]);

  useEffect(() => {
    const now = new Date();

    // Format the date in Dubai time zone using `toLocaleString`
    const dubaiTimeString = now.toLocaleString("en-CA", {
      timeZone: "Asia/Dubai",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    // Format it to "YYYY-MM-DDTHH:MM"
    const [date, time] = dubaiTimeString.split(", ");
    const formattedDateTime = `${date}T${time}`;
    setMinDateTime(formattedDateTime);
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    setDate(value);
    if (value === "") {
      setDateErr("This field is required");
    } else {
      setDateErr("");
    }
  };

  const handleStatus=(e)=>{
    setStatus(!status)
    updateStatus(!status)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (date == "") {
      setDateErr("This field is required");
      return;
    }
    const data = {
      date: date,
      id: id,
    };
    setLiveTokenDate(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
 
  const updateStatus=(status)=>{
    const data={
      id:id,
      status:status?'1':'0'
    }
    updateLiveTokenDateStatus(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  }

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Live Token Date</h1>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <input
                      type="datetime-local"
                      className="form-control"
                      onChange={handleChange}
                      value={date}
                      min={minDateTime}
                    />
                    <span className="text-danger">{dateErr}</span>
                  </div>
                  <div className="col-md-4">
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                  <div className="col-md-4">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={status}
                        onChange={handleStatus}
                      />
                      <label
                        class="form-check-label text-white"
                        for="flexSwitchCheckChecked"
                      >
                        Status
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default LiveTokenDate;
