import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./AuthContext";
import Login from "./components/auth/Login";
import CookieText from "./components/pages/cookieText/CookieText";
import LiveTokenDate from "./components/pages/livetokenDate/LiveTokenDate";
import PrevTopRankUser from "./components/pages/topRankUser/PrevTopRankUser";
import TopRankUser from "./components/pages/topRankUser/TopRankUser";
import AdminTransaction from "./components/pages/transaction/AdminTransaction";
import UserSendMinAmount from "./components/pages/transaction/UserSendMinAmount";
import UserTransaction from "./components/pages/transaction/UserTransaction";
import Users from "./components/pages/users/Users";
import YouTubeUrl from "./components/pages/youtubeUrl/YouTubeUrl";
import Dashboard from "./components/widgets/Dashboard";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  const { login } = useAuth();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={<Dashboard />} />}
          />

          <Route
            path="/users"
            element={<ProtectedRoute component={<Users />} />}
          />
          <Route
            path="/user-transaction"
            element={<ProtectedRoute component={<UserTransaction />} />}
          />
        
        <Route
            path="/admin-transaction"
            element={<ProtectedRoute component={<AdminTransaction />} />}
          />
          <Route
            path="/user-send-min-amount"
            element={<ProtectedRoute component={<UserSendMinAmount />} />}
          />
           <Route
            path="/top-rank-user"
            element={<ProtectedRoute component={<TopRankUser />} />}
          />
           <Route
            path="/prev-top-rank-user"
            element={<ProtectedRoute component={<PrevTopRankUser />} />}
          />
          <Route
            path="/youtube-url"
            element={<ProtectedRoute component={<YouTubeUrl />} />}
          />
          <Route
            path="/live-token-date"
            element={<ProtectedRoute component={<LiveTokenDate />} />}
          />
      <Route
            path="/cookie-text"
            element={<ProtectedRoute component={<CookieText />} />}
          />
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
