import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGetCurrentStageQuery } from "../../redux/stageApi";
import { useSetAddAminBonusMutation } from "../../redux/userApi";

function AddAdminBonus(props) {
  const { data: currentStage } = useGetCurrentStageQuery();
  const [setAddAminBonus]=useSetAddAminBonusMutation()
  const [disable, setDisable] = useState(false);
  const [bonus, setBonus] = useState(0);
  const [bonusErr, setBonusErr] = useState("");
  const [usdtamount, setUsdtamount] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "bonus") {
      setBonus(value);
      if (currentStage?.length > 0 && value) {
        setUsdtamount(
          parseFloat(value) * parseFloat(currentStage[0].ukc_token_price)
        );
      }
      setBonusErr("")
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (bonus <= 0) {
      setBonusErr("Please enter valid bonus");
      return false;
    }
    setDisable(true);
    const data = { bonus: bonus,id:props?.id };
    setAddAminBonus(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setDisable(false);
        handleClose()
      } else {
        toast.dismiss();
        toast.error(result.data.message);
        setDisable(false);
      }
    });
  };

  const handleClose = () => {
    props.setShow(false);
    setBonus("");
    setBonusErr("");
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Add Bonus</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <lable className="form-label">Bonus</lable>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Enter Bonus"
                  value={bonus}
                  name="bonus"
                  onChange={handleChange}
                  onKeyPress={(event) => {
                    const inputValue = event.target.value;

                    // Allow only numbers and one decimal point
                    if (!/[0-9]/.test(event.key) && event.key !== ".") {
                      event.preventDefault();
                    }

                    // Prevent entering more than one decimal point
                    if (event.key === "." && inputValue.includes(".")) {
                      event.preventDefault();
                    }
                  }}
                />
                <span className="text-danger">{bonusErr}</span>
              </div>
              <div className="col-md-6">
                <lable className="form-label">USDT</lable>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="USDT"
                  value={usdtamount}
                  name="usdtamount"
                  readOnly
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              handleSubmit(e);
            }}
            type="submit"
            disabled={disable}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddAdminBonus;
