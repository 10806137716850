import React, { useState, memo, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUpdateCookieTextMutation } from "../../redux/cookietextApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function UpdateCookietextModal(props) {
  const [updateCookieText] = useUpdateCookieTextMutation();
  const [disable, setDisable] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [code, setCode] = useState("");
  const [percentage, setPercentage] = useState("0");
  const [id, setId] = useState("");

  useEffect(() => {
    if (props?.currentRecord?.text) {
      const decodedText = decodeURIComponent(
        escape(atob(props?.currentRecord?.text))
      );
      setDescription(decodedText);
      setCode(props.currentRecord.code ? props.currentRecord.code : "");
      setPercentage(
        props.currentRecord.percentage ? props.currentRecord.percentage : "0"
      );
      setId(props?.currentRecord?.id);
    }
  }, [props.currentRecord]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (description == "") {
      setDescriptionErr("This field is required");
      return false;
    }
    setDisable(true);
    const data = { text: description, code: code, percentage: percentage, id };
    updateCookieText(data)
      .then((result) => {
        if (result.data.status) {
          toast.dismiss();
          toast.success(result.data.message);
          setDisable(false);
          handleClose();
        } else {
          setDisable(false);
          toast.dismiss();
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        setDisable(false);
      });
  };

  const handleClose = () => {
    props.setUpdateShow(false);
    setDescription("");
    setDescriptionErr("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "code") {
      setCode(value);
    }
    if (name == "percentage") {
      setPercentage(value);
    }
  };

  return (
    <>
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>
            Update Description
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <lable className="form-label">Description *</lable>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                    ],
                    heading: {
                      options: [
                        {
                          model: "paragraph",
                          title: "Paragraph",
                          class: "ck-heading_paragraph",
                        },
                        {
                          model: "heading1",
                          view: "h1",
                          title: "Heading 1",
                          class: "ck-heading_heading1",
                        },
                        {
                          model: "heading2",
                          view: "h2",
                          title: "Heading 2",
                          class: "ck-heading_heading2",
                        },
                        {
                          model: "heading3",
                          view: "h3",
                          title: "Heading 3",
                          class: "ck-heading_heading3",
                        },
                      ],
                    },
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />
                <span className="text-danger">{descriptionErr}</span>
              </div>
              <div className="col-md-6 mt-2">
                <lable className="form-label">Coupon Code</lable>
                <input
                  className="form-control"
                  value={code}
                  name="code"
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mt-2">
                <lable className="form-label">Reward Percentage(%)</lable>
                <input
                  className="form-control"
                  value={percentage}
                  name="percentage"
                  onChange={handleChange}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              handleSubmit(e);
            }}
            type="submit"
            disabled={disable}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default memo(UpdateCookietextModal);
