export const stakeStatus=[
  {
    key:"Active",
    value:"1"
  },
  {
    key:"Expire",
    value:"2"
  }
]

export const transStatus=[
  {
    key:"Pending",
    value:"1"
  },
  {
    key:"Completed",
    value:"2"
  },
  {
    key:"Rejected",
    value:"3"
  },
]

export const ChainTransType={
  "4":"TRON",
  "5":"SOL"
}

export const currencyTransTronType={
  "4":{"0":"TRX",
  "1":"USDT (TRC20)"},
  "5":{"0":"SOL",
  "1":"USDT (SOL)"}
}

export const transTypeEnum=[
  {
    value:"Buy",
    key:"Buy"
  },
  {
    value:"Reward",
    key:"Reward"
  },
  // {
  //   value:"Referral Bonus",
  //   key:"Referral Bonus"
  // },
  // {
  //   value:"Admin Bonus",
  //   key:"Admin Bonus"
  // },
]


