import { addTopRankDateApi, getAllTopRankDateApi, getTopRankDateApi, updatetopRankDateApi } from "../components/constant/Api";
import { myApi } from "./api";

export const topRankApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getTopRankDate: builder.query({
      query: () => ({
        url: getTopRankDateApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
      providesTags: (_) => ["toprankdate"],
    }),
    getAllTopRankDate: builder.query({
      query: () => ({
        url: getAllTopRankDateApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
      providesTags: (_) => ["toprankdate"],
    }),
    setTopRankDate: builder.mutation({
      query: (post) => ({
        url: addTopRankDateApi,
        method: "POST",
        body:post
      }),
      invalidatesTags: (_) => ["toprankdate"],
    }),
    updateTopRankDate: builder.mutation({
        query: (post) => ({
          url: updatetopRankDateApi,
          method: "PUT",
          body:post
        }),
        invalidatesTags: (_) => ["toprankdate"],
      }),
  }),
});

export const {useSetTopRankDateMutation,useGetTopRankDateQuery,useUpdateTopRankDateMutation,useGetAllTopRankDateQuery} = topRankApi;