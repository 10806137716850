import React from "react";
import { Link } from "react-router-dom";

function SideBar() {
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/dashboard"
                ? "nav-link active"
                : "nav-link"
            }
            to="/dashboard"
          >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/users"
                ? "nav-link active"
                : "nav-link"
            }
            to="/users"
          >
            <i className="bi bi-people"></i>
            <span>Users</span>
          </Link>
        </li>
     
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/user-transaction"
                ? "nav-link active"
                : "nav-link"
            }
            to="/user-transaction"
          >
            <i className="bi bi-cash"></i>
            <span>User Transaction</span>
          </Link>
        </li>
      
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/top-rank-user"
                ? "nav-link active"
                : "nav-link"
            }
            to="/top-rank-user"
          >
           <i className="bi bi-award"></i>
            <span>Top Rank User</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/prev-top-rank-user"
                ? "nav-link active"
                : "nav-link"
            }
            to="/prev-top-rank-user"
          >
          <i className="bi bi-star"></i>
            <span>Prev Top Rank User</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/cookie-text"
                ? "nav-link active"
                : "nav-link"
            }
            to="/cookie-text"
          >
           <i className="bi bi-cookie"></i>
            <span>Cookie Text</span>
          </Link>
        </li>
      
      </ul>
    </aside>
  );
}

export default SideBar;
