import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import { useGetTopRankDateQuery } from "../../../redux/topRankApi";
import TopRankDateModal from "../../partial/TopRankDateModal";
import UpdateTopRankDateModal from "../../partial/UpdateTopRankDateModal";
import { useGetTopRankUserQuery } from "../../../redux/topRankUserApi";

function TopRankUser() {
  const { data: topRankDate } = useGetTopRankDateQuery();
  const {data}=useGetTopRankUserQuery()
  const [show, setShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if(topRankDate?.length>0){

      const interval = setInterval(() => {
        const now = new Date().getTime();
        const startTime = new Date(
          topRankDate && topRankDate[0]?.end_date
        ).getTime();
        let t;
        t = startTime - now;
        if (t <= 0) {
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        } else {
          const days = Math.floor(t / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((t % (1000 * 60)) / 1000);
          setTimeLeft({ days, hours, minutes, seconds });
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, [topRankDate]);

  const memoizedTimeLeft = useMemo(() => {
    return timeLeft;
  }, [timeLeft]);

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "wallet_address",
      text: "Wallet Address",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "ranks",
      text: "Rank",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "total_referral",
      text: "Total Referral",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    {
      key: "referral_ukc_token",
      text: "Referral Ukc Token",
      className: "email",
      align: "left",
      sortable: true,
    },

    {
      key: "usdt_amount",
      text: "USDT Amount ",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Top Rank Users</h1>
          <div className="flex_timer">
            <div id="clockdiv">
              <div className="t_box">
                <span className="days" id="day">
                  {memoizedTimeLeft.days.toString().padStart(2, "0")}
                </span>
                <div className="smalltext">Days</div>
              </div>
              <div className="t_box">
                <span className="hours" id="hour">
                  {memoizedTimeLeft.hours.toString().padStart(2, "0")}
                </span>
                <div className="smalltext">Hours</div>
              </div>
              <div className="t_box">
                <span className="minutes" id="minute">
                  {memoizedTimeLeft.minutes.toString().padStart(2, "0")}
                </span>
                <div className="smalltext">Minutes</div>
              </div>
              <div className="t_box">
                <span className="seconds" id="second">
                  {memoizedTimeLeft.seconds.toString().padStart(2, "0")}
                </span>
                <div className="smalltext">Seconds</div>
              </div>
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => {
                setShow(true);
              }}
            >
              Set New Date
            </button>
            <button
              className="btn btn-primary ms-2"
              onClick={() => {
                setUpdateShow(true);
              }}
              disabled={timeLeft.seconds==0?true:false}
            >
              Update Date
            </button>
          </div>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>
      <TopRankDateModal show={show} setShow={setShow} />
      <UpdateTopRankDateModal updateShow={updateShow} setUpdateShow={setUpdateShow} currentRecord={topRankDate}/>
      <Footer />
    </>
  );
}

export default TopRankUser;
