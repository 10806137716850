import React, { useState,useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUpdateTopRankDateMutation } from "../../redux/topRankApi";

function UpdateTopRankDateModal(props) {
    const [updateTopRankDate] = useUpdateTopRankDateMutation();
  const [disable, setDisable] = useState(false);
  const [startDate, setStartDate] = useState("0");
  const [endDate, setEndDate] = useState("");
  const [minEndDate,setMinEndDate]=useState("")
  const [id, setId] = useState("")

  useEffect(() => {
     if(props?.currentRecord){
        const data=props?.currentRecord[0]
        setStartDate(data?.start_date)
        setEndDate(data?.end_date)
        setMinEndDate(new Date().toISOString().substr(0, 10))
        setId(data?.id)
     }
  }, [props?.currentRecord])
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
      const newEndDate = new Date(value);
    newEndDate.setDate(newEndDate.getDate() + 1)
    setMinEndDate(newEndDate.toISOString().split('T')[0])
      setEndDate(newEndDate.toISOString().split('T')[0]);
    }
    if (name === "endDate") {
      setEndDate(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { start_date: startDate, end_date: endDate,id:id };
    updateTopRankDate(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setDisable(false);
        handleClose()
      } else {
        toast.dismiss();
        toast.error(result.data.message);
        setDisable(false);
      }
    });
  };

  const handleClose = () => {
    props.setUpdateShow(false);
    setStartDate("")
    setEndDate("")
    setMinEndDate("")
  };

  return (
    <>
    <Modal show={props.updateShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "black" }}>Update Date </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form>
          <div className="row">
            <div className="col-md-6">
              <lable className="form-label">Start Date</lable>
              <input
                type="date"
                className="form-control"
                placeholder="Start Date"
                value={startDate}
                name="startDate"
                onChange={handleChange}
                min={new Date().toISOString().substr(0, 10)}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
            <div className="col-md-6">
            <lable className="form-label">Start Date</lable>
              <input
                type="date"
                className="form-control"
                placeholder="End date"
                value={endDate}
                name="endDate"
                onChange={handleChange}
                min={minEndDate}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={(e) => {
            handleSubmit(e);
          }}
          type="submit"
          disabled={disable}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default UpdateTopRankDateModal