import { addYoutubeUrlApi, getyoutubeUrlapi } from "../components/constant/Api";
import { myApi } from "./api";

export const youtubeUrlApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    setYoutubeUrl: builder.mutation({
        query: (post) => ({
          url: addYoutubeUrlApi,
          method: "POST",
          body:post
        }),
        invalidatesTags: (_) => ["youtubeUrl"],
      }),  
    getYouTubeUrl: builder.query({
      query: () => ({
        url: getyoutubeUrlapi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
      providesTags: (_) => ["youtubeUrl"],
    }),


  }),
});

export const {useGetYouTubeUrlQuery,useSetYoutubeUrlMutation} = youtubeUrlApi;