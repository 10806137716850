import React,{useState,useEffect} from 'react'
import { useGetAllTopRankDateQuery } from '../../../redux/topRankApi'
import Footer from '../../widgets/Footer'
import Header from '../../widgets/Header'
import SideBar from '../../widgets/SideBar'
import ReactDatatable from "@mkikets/react-datatable";
import { useGetPrevTopRankUserMutation } from '../../../redux/topRankUserApi'

function PrevTopRankUser() {
    const {data}=useGetAllTopRankDateQuery()
    const [getPrevTopRankUser,{data:topUser}]=useGetPrevTopRankUserMutation()
    const [topDateId, setTopDateId] = useState('')

    useEffect(() => {
      if(data?.length>0){
        setTopDateId(data[0]?.id)
      }
    }, [data])

    useEffect(() => {
       if(topDateId){
        getPrevTopRankUser({id:topDateId})
       }
    }, [topDateId])
    
    const handleChange=(e)=>{
        setTopDateId(e.target.value)
    }

    const columns = [
        {
          key: "srno.",
          text: "Sr.No.",
          className: "sr_no.",
          align: "left",
          sortable: true,
          cell: (row, index) => index + 1,
        },
        {
          key: "wallet_address",
          text: "Wallet Address",
          className: "email",
          align: "left",
          sortable: true,
        },
        {
          key: "rank",
          text: "Rank",
          className: "name",
          align: "left",
          sortable: true,
        },
        {
          key: "total_referral",
          text: "Total Referral",
          className: "mobile_number",
          align: "left",
          sortable: true,
        },
        {
          key: "referral_ukc_token",
          text: "Referral Ukc Token",
          className: "email",
          align: "left",
          sortable: true,
        },
    
        {
          key: "usdt_amount",
          text: "USDT Amount ",
          className: "mobile_number",
          align: "left",
          sortable: true,
        },
        
      ];
    
      const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Fund Request List",
        no_data_text: "No record found!",
        button: {
          // print: true,
          // csv: true,
        },
        language: {
          filter: "Search in records...",
    
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
      };

  return (
    <>
     <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Previous Top Rank Users</h1>
          <div>
            <select className='form-control' value={topDateId} onChange={handleChange}>
                {data?.map((list)=>{
                   return (
                    <option value={list.id}>{list.start_date} to {list.end_date}</option>
                   )
                })}
            </select>
          </div>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
              <ReactDatatable
                config={config}
                records={topUser}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  )
}

export default PrevTopRankUser