import React,{useState} from 'react'
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { useDeleteCookieTextMutation, useGetCookieTextQuery } from '../../../redux/cookietextApi';
import AddCookietextModal from '../../partial/AddCookietextModal';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from 'react-toastify';
import UpdateCookietextModal from '../../partial/UpdateCookietextModal';

function CookieText() {
    const {data}=useGetCookieTextQuery()
    const [deleteCookieText]=useDeleteCookieTextMutation()
    const [show, setShow] = useState(false)
    const [updateShow, setUpdateShow] = useState(false)
    const [disable, setDisable] = useState(false)
    const [currentRecord, setCurrentRecord] = useState({})

    const columns = [
        {
          key: "srno.",
          text: "Sr.No.",
          className: "sr_no.",
          align: "left",
          sortable: true,
          cell: (row, index) => index + 1,
        },
        {
          key: "text",
          text: "Text",
          className: "email",
          align: "left",
          sortable: true,
          cell: (record) => {
            // const decodedText = Buffer.from(record.text, 'base64').toString('utf8');
            const decodedText = decodeURIComponent(escape(atob(record.text)));
            return <><div dangerouslySetInnerHTML={{ __html: decodedText}}/></>;
          },
        },
        {
          key: "code",
          text: "Coupon Code",
          className: "email",
          align: "left",
          sortable: true,
       
        },
        {
          key: "percentage",
          text: "Reward Percentage(%)",
          className: "email",
          align: "left",
          sortable: true,
       
        },
        {
            key: "start_date",
            text: "Date",
            className: "email",
            align: "left",
            sortable: true,
            cell: (record) => {
              const date = record.createdAt;
              return <>{moment(date).format("DD/MM/YYYY")}</>;
            },
          },
          {
            key: "action",
            text: "Action",
            className: "Action d-flex",
            align: "left",
            sortable: true,
            cell: (record) => {
              return (
                <>
                 
                  <button
                    style={{
                      border: "1px solid #fff",
                      borderRadius: "3px",
                      background:"red",
                      padding: "0px 7px",
                    }}
                    onClick={() => {
                      deleteAlert(record.id);
                    }}
                    disabled={disable}
                  >
                    <i className="bi bi-trash" style={{ color: "#fff" }}></i>
                  </button>
                  <button
                    style={{
                      border: "1px solid #fff",
                      borderRadius: "3px",
                      background:"blue",
                      padding: "0px 7px",
                    }}
                    onClick={() => {
                      handleUpdate(record);
                    }}
               
                  >
                    <i className="bi bi-pencil" style={{ color: "#fff" }}></i>
                  </button>
                </>
              );
            },
          },
      ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Fund Request List",
        no_data_text: "No record found!",
        button: {
          // print: true,
          // csv: true,
        },
        language: {
          filter: "Search in records...",
    
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
      };

      const deleteAlert = (id) => {
        confirmAlert({
          title: "Confirm to submit",
          message: "Are you sure to do this.",
          buttons: [
            {
              label: "Yes",
              onClick: () => deleteCookieTextFun(id),
            },
            {
              label: "No",
            },
          ],
        });
      };
     
      const deleteCookieTextFun=async(id)=>{
        const data={id}
        setDisable(true)
        deleteCookieText(data).then((result) => {
          if (result.data.status) {
            toast.dismiss();
            toast.success(result.data.message);
            setDisable(false)
          }
          else{
            toast.dismiss();
            toast.error(result.data.message);
            setDisable(false)
          }
        });
      }

      const handleUpdate=(record)=>{
        setUpdateShow(true)
        setCurrentRecord(record)
      }
    
  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Cookie Text List</h1>
          <div>
            <button className='btn btn-primary' onClick={()=>{setShow(true)}}>Add +</button>
          </div>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
            <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <AddCookietextModal show={show} setShow={setShow}/>
      <UpdateCookietextModal updateShow={updateShow} setUpdateShow={setUpdateShow} currentRecord={currentRecord}/>
    </>
  )
}

export default CookieText