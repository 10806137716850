import React,{useState} from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { useGetUsersendMinAmountQuery, useSetApproveMinAmountMutation } from "../../../redux/transactionApi";
import { ChainTransType, currencyTransTronType } from "../../constant/enum";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function UserSendMinAmount() {
  const { data } = useGetUsersendMinAmountQuery();
  const [setApproveMinAmount]=useSetApproveMinAmountMutation()
  const [disable, setDisable] = useState(false)

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "from_address",
      text: "From Address",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "to_address",
      text: "To Address",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "chain",
      text: "Chain",
      className: "mobile_number",
      align: "left",
      sortable: true,
      cell: (record) => {
     
        return <>{ChainTransType[record.chain]}</>;
      },
    },
    {
      key: "currency",
      text: "Currency",
      className: "mobile_number",
      align: "left",
      sortable: true,
      cell: (record) => {
     
        return <>{currencyTransTronType[record.chain][record.currency]}</>;
      },
    },
    {
      key: "amount",
      text: "Amount",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
        key: "ukc_token",
        text: "Ukc Token",
        className: "email",
        align: "left",
        sortable: true,
      },
    {
      key: "recieve_amount",
      text: "Recieve Amount",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "get_recieve_ukc",
      text: "Send Ukc Token",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "trans_id",
      text: "Transaction Id",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "start_date",
      text: "Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action d-flex",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
           
            <button
              style={{
                border: "1px solid #fff",
                borderRadius: "3px",
                background:"green",
                padding: "0px 7px",
              }}
              onClick={() => {
                updateAlert(record.id);
              }}
              disabled={disable}
            >
              <i className="bi bi-check" style={{ color: "#fff" }}></i>
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const updateAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => approveAmount(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
 
  const approveAmount=async(id)=>{
    const data={id}
    setDisable(true)
    setApproveMinAmount(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setDisable(false)
      }
      else{
        toast.dismiss();
        toast.error(result.data.message);
        setDisable(false)
      }
    });
  }

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Users Send Min Amount List</h1>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default UserSendMinAmount;
