import React, { useState,useEffect } from 'react'
import { useGetYouTubeUrlQuery, useSetYoutubeUrlMutation } from '../../../redux/youtubeUrlApi'
import { urlRegexpatter } from '../../pattern/Pattern'
import Footer from '../../widgets/Footer'
import Header from '../../widgets/Header'
import SideBar from '../../widgets/SideBar'
import { toast } from "react-toastify";

function YouTubeUrl() {
  const {data:record}=useGetYouTubeUrlQuery()
  const [setYoutubeUrl]=useSetYoutubeUrlMutation()
  const [url, setUrl] = useState("")
    const [urlErr, setUrlErr] = useState("")
    const [id, setId] = useState("")

    useEffect(() => {
       if(record &&  record?.length>0){
        setUrl(record[0]?.youtube_link)
        setId(record[0]?.id)
       }
    }, [record])
    


    const handleChange=(e)=>{
        const {value}=e.target
        setUrl(value)
        if(value===""){
            setUrlErr("This field is required")
        }
        else if(!urlRegexpatter.test(value)){
            setUrlErr("Invalid url")
        }
        else{
            setUrlErr("")
        }
    }
    
    const handleSubmit=(e)=>{
          e.preventDefault()
          if(url==""){
            setUrlErr("This field is required")
            return
          }
          if(!urlRegexpatter.test(url)){
            setUrlErr("Invalid url")
            return
        }
          const data={
            youtube_url:url,
            id:id
          }
          setYoutubeUrl(data).then((result) => {
            if (result.data.status) {
              toast.dismiss();
              toast.success(result.data.message);
            }
            else{
              toast.dismiss();
              toast.error(result.data.message);
            
            }
          });
    }

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Youtube Url</h1>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
                <form onSubmit={handleSubmit}>

                <div className='row'>
             <div className='col-md-8'>
               <input placeholder='Enter Youtube Url' className='form-control' onChange={handleChange} value={url}/>
               <span className='text-danger'>{urlErr}</span>
             </div>
             <div className='col-md-4'>
              <button className='btn btn-primary' type='submit'>Submit</button>
             </div>
                </div>
                </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default YouTubeUrl
