import { addAminBonusApi, getUserApi } from "../components/constant/Api";
import { myApi } from "./api";

export const userApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
  
    getUser: builder.query({
      query: () => ({
        url: getUserApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
      providesTags: (_) => ["user"],
    }),
    setAddAminBonus: builder.mutation({
      query: (post) => ({
        url: addAminBonusApi,
        method: "POST",
        body:post
      }),
      invalidatesTags: (_) => ["transaction"],
    }),

  }),
});

export const {useGetUserQuery,useSetAddAminBonusMutation} = userApi;