import React from 'react'
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { useGetAdminTransactionQuery } from '../../../redux/transactionApi';

function AdminTransaction() {
    const {data}=useGetAdminTransactionQuery()

    const columns = [
        {
          key: "srno.",
          text: "Sr.No.",
          className: "sr_no.",
          align: "left",
          sortable: true,
          cell: (row, index) => index + 1,
        },
        {
          key: "from_address",
          text: "From Address",
          className: "name",
          align: "left",
          sortable: true,
        },
        {
          key: "to_address",
          text: "To Address",
          className: "email",
          align: "left",
          sortable: true,
        },
        {
          key: "chain",
          text: "Chain",
          className: "mobile_number",
          align: "left",
          sortable: true,
        },
        {
          key: "currency",
          text: "Currency",
          className: "mobile_number",
          align: "left",
          sortable: true,
        },
        {
          key: "amount",
          text: "Amount",
          className: "email",
          align: "left",
          sortable: true,
        },
        {
            key: "transaction_id",
            text: "Transaction Id",
            className: "email",
            align: "left",
            sortable: true,
          },
        {
          key: "start_date",
          text: "Date",
          className: "email",
          align: "left",
          sortable: true,
          cell: (record) => {
            const date = record.createdAt;
            return <>{moment(date).format("DD/MM/YYYY")}</>;
          },
        },
      ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Fund Request List",
        no_data_text: "No record found!",
        button: {
          // print: true,
          // csv: true,
        },
        language: {
          filter: "Search in records...",
    
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
      };
    
  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Admin Transaction List</h1>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
            <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default AdminTransaction