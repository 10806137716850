import { getPrevTopRankUserApi, getTopRankUserApi } from "../components/constant/Api";
import { myApi } from "./api";

export const topRankUserApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getTopRankUser: builder.query({
      query: () => ({
        url: getTopRankUserApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        console.log(response,"response")
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getPrevTopRankUser: builder.mutation({
        query: (post) => ({
          url: getPrevTopRankUserApi,
          method: "POST",
          body:post
        }),
        transformResponse: (response, meta, arg) => {
          console.log(response,"response")
          return response.status ? response?.data ?? [] :[];
        },
      }),
  }),
});

export const {useGetTopRankUserQuery,useGetPrevTopRankUserMutation} = topRankUserApi;