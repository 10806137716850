import { approveMinamountApi, getAdminTransactionApi, getUserSendMinAmountApi, getUserTransactionApi } from "../components/constant/Api";
import { myApi } from "./api";

export const transactionApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserTransaction: builder.query({
      query: () => ({
        url: getUserTransactionApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
      providesTags:(_)=>["transaction"]
    }),
    getAdminTransaction: builder.query({
      query: () => ({
        url: getAdminTransactionApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    getUsersendMinAmount: builder.query({
      query: () => ({
        url: getUserSendMinAmountApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
      providesTags: (_) => ["usersendminamount"],
    }),
    setApproveMinAmount: builder.mutation({
      query: (post) => ({
        url: approveMinamountApi,
        method: "POST",
        body:post
      }),
      invalidatesTags: (_) => ["usersendminamount"],
    }),
  }),
});

export const {useGetAdminTransactionQuery,useGetUserTransactionQuery,useGetUsersendMinAmountQuery,useSetApproveMinAmountMutation } = transactionApi;