import { addLiveTokenDateApi, getLiveTokenaDateApi, updateLiveTokenDateStatusapi } from "../components/constant/Api";
import { myApi } from "./api";

export const liveTokenDateApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    setLiveTokenDate: builder.mutation({
        query: (post) => ({
          url: addLiveTokenDateApi,
          method: "POST",
          body:post
        }),
        invalidatesTags: (_) => ["liveTokenDate"],
      }),  
      updateLiveTokenDateStatus: builder.mutation({
        query: (post) => ({
          url: updateLiveTokenDateStatusapi,
          method: "POST",
          body:post
        }),
        invalidatesTags: (_) => ["liveTokenDate"],
      }), 
    getLiveTokenDate: builder.query({
      query: () => ({
        url: getLiveTokenaDateApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
      providesTags: (_) => ["liveTokenDate"],
    }),


  }),
});

export const {useGetLiveTokenDateQuery,useSetLiveTokenDateMutation,useUpdateLiveTokenDateStatusMutation} = liveTokenDateApi;